'use strict'

$(function() {

  const author = `
    <div style="position: fixed;bottom: 0;right: 20px;background-color: #fff;box-shadow: 0 4px 8px rgba(0,0,0,.05);border-radius: 3px 3px 0 0;font-size: 12px;padding: 5px 10px;">
      By <a href="https://arthurmonney.com">Arthur Monney</a>
    </div>
  `
  $('body').append(author);

  $(`input[type='password'][data-eye]`).each(function(i) {
    const $this = $(this),
      id = 'eye-password-' + i;

    $this.wrap($("<div/>", {
      style: 'position:relative',
      id: id
    }))

    $this.css({ paddingRight: 60 })

    $this.after($('<div/>', {
      html: 'Show',
      class: 'btn btn-primary btn-sm',
      id: 'passeye-toggle-'+i,
    }).css({
      position: 'absolute',
      right: 10,
      top: ($this.outerHeight() / 2) - 12,
      padding: '2px 7px',
      fontSize: 12,
      cursor: 'pointer',
    }))

    $this.after($('<input/>', {
      type: 'hidden',
      id: 'passeye-' + i
    }))

    const invalid_feedback = $this.parent().parent().find('.invalid-feedback');

    if(invalid_feedback.length) {
      $this.after(invalid_feedback.clone())
    }

    $this.on('keyup paste', function() {
      $('#passeye-'+i).val($this.val())
    })
    $('#passeye-toggle-'+i).on('click', function() {
      if($this.hasClass('show')) {
        $this.attr('type', 'password')
        $this.removeClass('show')
        $(this).removeClass('btn-outline-primary')
      } else {
        $this.attr('type', 'text')
        $this.val($('#passeye-'+i).val())
        $this.addClass('show')
        $(this).addClass('btn-outline-primary')
      }
    })
  })

  $('.my-login-validation').submit(function(event) {
    /*event.preventDefault()
    event.stopPropagation()*/
    const form = $(this), button = $('#btn-login');

    button.attr('disabled', true).html(`<span class='loader12'></span>`);

    if (form[0].checkValidity() === false) {
      button.removeAttr('disabled');
    }

    form.addClass('was-validated');
  });

});
